.campaignCollectionItem{
    position: relative;
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 5px;
}

.campaignCollectionItem-label{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
    max-width: 200px;
}

.campaignCollectionItem-remove{
    position: relative;
    width: fit-content;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: none;
    border: none;
    opacity: .66;
    transition: all ease 300ms;
    cursor: pointer;
    
    &:hover{
        opacity: 1;
    }

    svg{
        width: 12px;
    }
}