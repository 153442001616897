.campaigns {
  &-index {
    position: relative;
    padding: 0.5rem;
    width: 100%;
    max-width: 500px;
    overflow-y: hidden;
    margin: 1rem auto;
    height: fit-content;
    border-radius: 8px;

    .campaign {
      overflow: initial;
      margin-bottom: 2rem;

      .campaign-topbar {
        margin-top: -30px;
        margin-left: -5px;
        margin-bottom: 10px;
      }

      &:first-of-type {
        margin-top: 1rem;
      }
    }
  }

  &-index-header {
    text-align: center;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &-index-new {
    position: relative;
    text-align: center;
    border-top: none;
    border-top: none;
    cursor: pointer;
    font-weight: bold;
    opacity: 0.8;
    padding: 1rem;
    transition: opacity ease 300ms;
    min-height: 55px;
    border-radius: 4px;
    overflow: hidden;
    height: 80px;

    &:hover,
    &:focus-within {
      opacity: 1;
    }

    &-cover,
    &-options {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cover {
      background: var(--accent2);
      color: var(--background);
      opacity: 1;
      transition: all ease 300ms;
      transition-delay: 300ms;
      pointer-events: all;
      max-height: 80px;

      &:target,
      &:focus,
      &:focus-within {
        transition-delay: 100ms;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        max-height: 0px;
        color: transparent;
      }
    }

    &-options {
      overflow: hidden;
      pointer-events: all;
      button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        font-weight: bold;
        cursor: pointer;
        background: var(--accent2);
        color: var(--background);
        transition: all ease 300ms;
        transform: scale(1);

        &.tiered {
          background: var(--warning);
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &-index-fadeEdge {
    z-index: 2;

    &::after {
      content: "";
    }

    &,
    &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1rem;
      background: linear-gradient(
        180deg,
        var(--background),
        hsla(0, 0%, 100%, 0)
      );
    }
  }

  &-index-list,
  &-archive-list {
    padding: 0.3rem 0.1rem;
    padding-top: 1rem;
    transition: all ease 300ms;
    border-top: none;
    overflow-y: auto;
    border-bottom: solid 2px var(--background2);
  }

  &-index-item {
    width: 100%;
    margin-bottom: 0.33rem;
    opacity: 0.8;
    cursor: pointer;
    transition: all ease 300ms;

    &:hover {
      opacity: 1;
      background: var(--light);
    }

    &-id {
      opacity: 0.7;
      margin: 0;
      font-size: 0.66rem;
      pointer-events: none;
    }

    &-title {
      pointer-events: none;
    }
  }

  &-archive {
    &-topbar {
      margin-top: 1.5rem;
      width: 100%;
      display: flex;
      justify-content: center;
      color: var(--mid);
      cursor: pointer;
      margin-bottom: 1.5rem;

      &-label {
        padding: 0 0.5rem;
        font-weight: bold;
        text-transform: uppercase;
        border: 1px solid var(--mid);
        border-radius: 4px;
      }

      &-icon {
        margin-left: 0.5rem;
        transition: all ease 300ms;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
      }
    }

    &:not(.open) &-topbar-icon {
      transform: scaleY(-1);
    }

    &-list {
      transition: all ease 300ms;
      max-height: 1000px;

      .campaign {
        opacity: 0.5;
      }
    }

    &:not(.open) &-list {
      max-height: 0px;
      padding: 0;
      border-bottom: solid 2px transparent;
    }
  }
}
