.index-viewer {
  position: relative;
  padding: 0.5rem;
  width: 100%;
  max-width: 500px;
  // border: solid 2px var(--text);
  overflow: hidden;
  margin: 1rem auto;
  height: fit-content;
  border-radius: 8px;
}

.index-viewer-header {
  text-align: center;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.index-viewer-toolbar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border: solid 2px var(--background2);
  font-weight: bold;
  padding: 0.5rem;
  min-height: 80px;
  border-radius: 8px;

  & > div {
    margin-bottom: 8px;
  }
}

.index-viewer-text {
  font-size: 12px;
  width: 100%;
  padding: 2px;
  font-weight: 500;
  opacity: 0.6;
}
