.campaign-priceTier-add {
  margin-top: 5px;
  padding: 6px;
  vertical-align: top;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  margin-right: 8px;
  max-height: 0px;
  transition: all ease 300ms;
  pointer-events: none;
  opacity: 0;

  svg {
    width: 13px;
    margin-right: 8px;

    path {
      transition: fill ease 300ms;
    }
  }

  &:hover {
    svg path {
      fill: var(--accent2);
    }
  }
}

.campaign.targeted .campaign-priceTier-add {
  max-height: 50px;
  pointer-events: all;
  opacity: 1;
}

.campaign-priceTier {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 3px;

  &:first-of-type {
    margin-top: 1rem;

    .campaign-priceTier-input-label {
      display: flex;
    }
  }
}

.campaign-priceTier-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  &::after {
    content: "$";
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 13px;
  }
}

.campaign-priceTier-input-label {
  font-size: 11px;
  color: var(--mid);
  content: "";
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.campaign-priceTier-input {
  position: relative;
  margin-right: 5px;
  height: 25px;
  padding: 3px;
  border: solid 2px var(--more-grey);
  background: transparent;
  border-radius: 4px;
  width: 90px;
  transition: all ease 300ms;
  padding-left: 12px;
}

.campaign.targeted .campaign-priceTier-input {
  background: var(--background);
}

.campaign-priceTier-gift {
  outline: 0;
  padding: 3px;
  height: 25px;
  width: 90px;
  border-radius: 4px;
  border: solid 2px var(--more-grey);
  background: transparent;
  cursor: pointer;
  transition: all ease 300ms;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  text-align: left;

  &.targeted {
    border: solid 2px var(--accent);
  }
}

.campaign-priceTier-input-wrapper.gift-input::after {
  content: '';
}

.campaign.targeted .campaign-priceTier-gift {
  background: var(--background);

  &:hover{
    border: solid 2px var(--accent);
  }
}

.campaign-priceTier-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 300ms;
  pointer-events: none;
  opacity: 0;
}

.campaign.targeted .campaign-priceTier-actions {
  pointer-events: all;
  opacity: 1;
}

.campaign-priceTier-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 0.3rem;

  svg {
    width: 1rem;
  }

  &:hover {
    opacity: 1;

    svg path {
      fill: var(--accent2);
    }
  }
}

.campaign-priceTier-removal {
  position: relative;
  width: fit-content;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: none;
  opacity: 0.66;
  transition: all ease 300ms;
  cursor: pointer;

  svg {
    width: 12px;

    path {
      transition: fill ease 300ms;
    }
  }

  &:hover {
    opacity: 1;

    svg path {
      fill: crimson;
    }
  }
}

.campaign-scopedPriceTiers{
  display: flex;
  align-items: center;
  max-height: 0px;
  overflow: hidden;
  transition: all ease 300ms;
  label {
    margin-right: .5rem;
  }
}

.campaign.targeted .campaign-scopedPriceTiers{
  margin-top: 5px;
  max-height: 22px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  &-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  &-container {
    position: relative;
    width: 70%;
    height: 70%;
    transition: all ease 300ms;
    background: #ffffff;
    border: solid 2px var(--more-grey);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05),
      0px 0px 3px rgba(66, 71, 76, 0.33);
    border-radius: 8px;
    padding: 0.66rem;
    overflow: hidden;
    z-index: 2;
    padding: 1.5rem;
  }

  &-top {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: solid 1px var(--more-grey);
    padding-bottom: .3rem;
    margin-bottom: .3rem;
  }

  &-tierInfo {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    font-size: 11px;
    margin-bottom: .5rem;
    margin-right: .66rem;

    &-label {
      font-size: 13px;
      color: var(--text2);
      font-weight: 600;
      display: flex;
      align-items: center;

      svg{
        width: 15px;
        margin-left: 3px;
        height: auto;
      }
    }
  }

  &-heading { }

  &-search {
    width: 220px;
    padding: .33rem;
    font-size: 1.1rem;
    background: none;
    border: none;
    outline: none;
    padding-left: 0;
    border-bottom: 1px solid var(--accent2);
    font-weight: 300;
  }

  &-index {
    height: 100%;
    overflow: auto;
    padding: .3rem;
    padding-top: 1rem;
    padding-bottom: 6rem;
  }


  &-index-fadeEdge {
    z-index: 2;
    
    &::after{
      content: '';
    }
  
    &,
    &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1rem;
      background: linear-gradient(
        180deg,
        var(--background),
        hsla(0, 0%, 100%, 0)
      );
    }
  }
}
