.saveBtn{
  position: fixed;
  z-index: 10;
  top: 1rem;
  right: 1rem;
  background: var(--accent);
  color: var(--background);
  font-size: 1.4rem;
  padding: .5rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  opacity: .8;
  transition: all ease 300ms;
  border: none;

  &:hover{
    background: var(--accent2);
    opacity: 1;
  }

  &.saving{
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}


@keyframes pulse {
0% {
  opacity: 0.6;
}
50% {
  opacity: 0.1;
}
100% {
  opacity: 0.6;
}
}
