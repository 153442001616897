.addAsAction {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  transition: all ease 300ms;

  &.disabled {
    opacity: 0.4;
  }
  
  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.addAsAction-label {
  margin-right: 0.33rem;
}

.addAsAction-actions {
  display: flex;
  align-items: center;
}

.addAsAction-action {
  margin-right: 0.33rem;
  padding: 0.33rem;
  border-radius: 4px;
  cursor: pointer;
  border: solid 1px #000;
  color: #000;
  transition: all ease 150ms;
  background: none;
  font-weight: bold;

  &:hover {
    background: var(--accent);
    color: #fff;
    font-weight: bold;
  }

  &:active {
    transform: scale(1.05);
  }
}