.campaignEditor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaignEditor-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.campaignEditor-container {
  position: relative;
  width: 80%;
  height: 90%;
  transition: all ease 300ms;
  background: #ffffff;
  border: solid 2px var(--more-grey);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05),
    0px 0px 3px rgba(66, 71, 76, 0.33);
  border-radius: 8px;
  padding: 0.66rem;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1048px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
  }
}


.campaignEditor-campaign,
.campaignEditor-index {
  position: relative;
  height: 100%;
  width: 50%;
  max-width: 540px;
  
  @media (max-width: 1048px) {
    max-width: none;
    width: 100%;
    height: fit-content;

    .index-viewer,
    .specialCondition-index-list,
    .collection-index-list,
    .product-index-list{
      max-width: none;
    }
  }
}

.campaignEditor-campaign {
  padding: 1.5rem;
  overflow: auto;

  .campaign.targeted{
    margin-top: 3px;
    background: var(--light-grey);
    margin-bottom: 0;
  }

  @media (max-width: 1048px) {
    overflow: initial;
  }
}

.campaignEditor-index {
  padding: 1rem;
  
  overflow: auto;
  @media (max-width: 1048px) {
    overflow: initial;
  }
  .index-viewer {
    margin: 0;
  }
}
