.logItem {
  position: relative;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid var(--mid);
}

.logItem-heading {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 50px;
}

.logItem-heading:hover {
  background: var(--light);
}

.logItem-subheading {
  margin-bottom: 1rem;
  color: var(--text2);
}

.logItem-heading-timestamp {
  opacity: .8;
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 12px;
}

.logItem-heading-message {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.logItem-message-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 12px;
  background: var(--text);
  color: var(--background);
  padding: 6px 4px;
}

.logItem-message-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
  font-size: 14px;
}

.logItem-info {
  position: relative;
  max-height: 0px;
  overflow: hidden;
  transition: all ease 300ms;
}

.logItem.expanded .logItem-info{
  max-height: 1000px;
}

.logItem-info-property {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

.logItem-info-property-label {
  text-transform: uppercase;
  text-decoration: underline;
  margin-bottom: .5rem;
}

.logItem-info-property-value {
  background: var(--mid-grey);
  padding: .5rem;
  border-radius: 4px;
  width: 100%;
  overflow: auto;
  max-height: 640px;
}