.loggedActivity {
  padding: 1rem;
}

.loggedActivity-header {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.loggedActivity-heading {
  text-decoration: underline;
}

.loggedActivity-goback {
  background: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 4px;
  color: var(--background);
}

.loggedActivity-goback-icon {
  transform: rotate(90deg);
  margin-right: 5px;
  svg {
    max-width: 1rem;
    max-height: 1rem;
  }
}