.campaign {
  position: relative;
  width: 100%;
  padding: 0.33rem;
  opacity: 0.8;
  cursor: pointer;
  transition: all ease 300ms;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05),
    0px 0px 3px rgba(66, 71, 76, 0.33);
  border-radius: 8px;
  padding: 0.66rem;
  overflow: hidden;
  margin-bottom: 0.66rem;

  hr {
    margin: 0.33rem 0;
    width: 30px;
  }

  input {
    font-family: inherit;
  }

  * {
    pointer-events: none;
  }

  &.archived {
    &::before {
      content: "Archived";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: bold;
      transform: rotate(-12deg);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity ease 300ms;
      pointer-events: none;
    }
  }

  &.targeted,
  &:hover {
    opacity: 1;
    background: var(--mid-grey);
  }

  &.targeted {
    cursor: default;

    * {
      pointer-events: all;
    }

    .campaign-items {
      user-select: auto;
      pointer-events: all;
      opacity: 1;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: 10px;
    }

    .campaign-details {
      user-select: auto;
      pointer-events: all;
      opacity: 1;
      max-height: 720px;
      overflow: hidden;
      overflow-y: auto;
      transition: all ease 300ms;
    }

    .campaign-items-label {
      text-decoration: underline;
    }

    .campaign-topbar .campaign-close {
      max-width: 15px;
      margin-right: 5px;
    }

    &.archived {
      &::before {
        opacity: 0.3;
      }
    }
  }

  
}

.campaign-topbar {
  position: relative;
  width: 100%;
  height: 1rem;
  font-size: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .campaign-close {
    cursor: pointer;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 300ms;
    margin-right: 0px;
    max-width: 0px;
  }
}

.campaign-type {
  font-weight: bold;
  font-size: 0.66rem;
  padding: 0.1rem;
  opacity: 0.77;
}

.campaign-status {
  position: absolute;
  top: 0rem;
  right: 0rem;
  background: grey;
  font-weight: bold;
  font-size: 0.88rem;
  color: #fff;
  padding: 0.5rem;
  opacity: 0.77;
  cursor: pointer;
  border-top-right-radius: 8px;
  transition: opacity ease 300ms;
  border: none;
  text-transform: uppercase;
  width: fit-content;
  text-align: center;

  &.active {
    background: var(--accent2);
  }

  &.scheduled {
    background: var(--warning);
  }

  &.live {
    background: var(--live);
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .campaign.archived,
  &.expired {
    background: grey;
    opacity: 0.4;
  }

  &:hover {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.6;
  }
}

.campaign-title {
  display: block;
  font-size: 1.17em;
  font-weight: bold;
  margin-bottom: 0.33rem;
  width: fit-content;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 0px;
  transition: all ease 300ms;
  max-width: calc(100% - 100px);
}

.campaign.targeted .campaign-title {
  padding: 3px;
  border: solid 1px var(--text);
}

.campaign-details,
.campaign-items {
  user-select: none;
  pointer-events: none;
  opacity: 1;
  overflow: hidden;
  height: fit-content;
  max-height: 0px;
  transition: all ease 300ms;
}

.campaign.targeted .campaign-details {
  overflow: visible;
}

.campaign-items-label {
  text-decoration: none;
  margin-bottom: 0.1rem;
  font-size: 15px;
  font-weight: 500;
}

.campaign-info {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  max-width: 100%;
  font-family: inherit;
}

.campaign-options {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
}

.campaign-delete,
.campaign-convert {
  padding: 3px 6px;
  vertical-align: top;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  margin-right: 8px;

  svg {
    width: 13px;
    height: auto;
    margin-right: 8px;
    path {
      transition: fill ease 300ms;
    }
  }

  &:hover {
    svg path {
      fill: var(--accent2);
    }
  }
}

.campaign-delete {
  &:hover {
    svg path {
      fill: crimson;
    }
  }
}

.campaign-warnings {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 10px;
  font-weight: bold;
  color: #df8a13;
  text-align: right;
}

.campaign-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40px;
  right: 0;
  transition: all ease 300ms;
  min-width: 90px;
}

.campaign:not(.targeted) .campaign-actions {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.campaign-action {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  margin: 0 0.33rem;
  cursor: pointer;
  opacity: 0.5;
  transition: all ease 300ms;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: auto;
      width: auto;
      max-width: 14px;
      max-height: 14px;
    }
    
    &.unarchive {
      svg {
        height: auto;
        width: auto;
        max-width: 28px;
        max-height: 28px;
      }
    }
  }

  &-label {
    position: absolute;
    color: transparent;
    pointer-events: none !important;
  }
}

.campaign-action:hover {
  opacity: 1;
}
