.appConfirm .modal-container {
  max-width: 350px;
}

.appConfirm .confirm {
  color: var(--accent);
  border: 1.5px solid var(--accent);
}

.appConfirm .confirm:hover {
  color: var(--background);
  background: var(--accent);
}
