.logsLink {
  position: fixed;
  z-index: 10;
  top: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text);
  transition: all ease 300ms;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

}


.logsLink-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  opacity: .8;
  
  &:hover {
    opacity: 1;
  }

}

.logsLink-label {
  transition: all ease 666ms;
  max-width: 0px;
  opacity: 0;
  white-space: nowrap;
}

.logsLink:hover .logsLink-label {
  max-width: 200px;
  opacity: 1;
}