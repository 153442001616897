.specialCondition-index-item {
  position: relative;
  width: 100%;
  opacity: 0.8;
  transition: all ease 150ms;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  user-select: none;


  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05), 0px 0px 3px rgba(66, 71, 76, 0.33);
  border-radius: 8px;
  padding: .66rem;
  overflow: hidden;
  margin-bottom: 0.66rem;

  &:hover {
    opacity: 1;
    background: var(--mid-grey);
  }
}

.specialCondition-index-item-id {
  opacity: 0.7;
  margin: 0;
  font-size: 0.66rem;
  pointer-events: none;
}

.specialCondition-index-item-title {
  pointer-events: none;
}

.specialCondition-index-item-inventory {
  font-size: 0.8rem;
  pointer-events: none;
}

.specialCondition-index-item-selectBox {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
}

.specialCondition-index-item-selectBox-circle {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #000;
  border-radius: 15px;
  cursor: pointer;

  &.selected{
      background: var(--accent);
      color: #fff;
  }

  svg{
    width: 80%;
    stroke: #fff;
    stroke-width: 1px;
  }
}

