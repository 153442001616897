.modal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 77777 !important;
}

@media (max-width: 540px) {
  .modal {
    justify-content: flex-end;
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(17, 17, 17, 0.33);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  transition: background ease 150ms, backdrop-filter ease 150ms;
}

.modal-container {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background: var(--background);
  max-width: 100%;
  max-height: 80%;
  border: 1px solid var(--light);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1),
    0 0 10px rgba(255, 255, 255, 0.1), 0 0 2px rgba(255, 255, 255, 0.1),
    0 0 5px rgba(255, 255, 255, 0), 0 0 1px rgba(255, 255, 255, 0),
    0 0 40px rgba(255, 255, 255, 0), 0 0 1px rgba(255, 255, 255, 0),
    0 0 1px rgba(255, 255, 255, 0);
}

@media (max-width: 540px) {
  .modal-container {
    width: 100%;
    padding-bottom: 2rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    min-height: 220px;
  }
}

.modal-helptext {
  font-size: 13px;
  color: var(--text2);
  margin-top: 0.5rem;
  text-align: center;
}

.modal-text {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 15px;
}

.modal-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-action {
  margin: 0 0.5rem;
  background: none;
  border: 1.5px solid var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
  cursor: pointer;
  min-height: 40px;
  min-width: 84px;
  border-radius: 40px;
  transition: all ease 300ms;
}

.modal-action:hover {
  color: var(--background);
  background: var(--text);
}