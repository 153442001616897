.collection-index-list {
  padding: 0.5rem;
  overflow-y: auto;
  max-width: 500px;
  // border: solid 2px var(--text);
  border-top: solid 2px var(--background2);
  border-bottom: solid 2px var(--background2);
  border-top: none;
  max-height: 80vh;
}

.collection-index-search {
  width: 220px;
  padding: 0.33rem;
  font-size: 1.1rem;
  background: none;
  border: none;
  outline: none;
  padding-left: 0;
  font-weight: 300;
  border-bottom: 1px solid var(--background2);
  font-weight: 300;
  margin: 0.33rem;
}
