:root{
  --ease: ease-in-out;
  --cubic: cubic-bezier(.12,.67,.53,1);
  --font-family: Madera, sans-serif;
  --text: #000000;
  --text2: #474747;
  --background: #ffffff;
  --background2: #E5E5E5;
  --accent: #80B4E2;
  --accent2: #6f9fff;
  --light: #FAFAFA;
  --danube: #64A8D7;
  --light-grey: #FAFAFA;
  --mid-grey: #F1F1F1;
  --more-grey: #e6e3e3;
  --dark-grey: #C4C4C4;
  --dusty-grey: #969696;
  --biscay: #1d4069;
  --mid: rgb(127, 127, 127);
  --dark: rgb(64, 64, 64);
  // E55812
  --warning: #f0ad4e;
  --live: #dc143c;
}