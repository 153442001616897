
.viewSelector{
    display: flex;
    flex-direction: column;
  }
  
  .viewSelector-label{
    margin-right: .33rem;
    display: flex;
    align-items: center;

    span{
      margin-left: 3px;
      margin-top: 2px;
    }
  }
  
  .viewSelector-selections{
    display: flex;
    align-items: center;
  }
  
  .viewSelector-selection{
    margin-right: .33rem;
    padding: .33rem;
    border-radius: 4px;
    cursor: pointer;
    border: solid 1px #000;
    transition: all ease 150ms;
    background: none;
    font-weight: bold;
  
    &.selected{
      background: var(--accent);
      color: #fff;
      font-weight: bold;
      text-decoration: underline;
    }
  
  
    &:active{
      transform: scale(1.05);
    }
  }