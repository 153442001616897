@import "./variables";
@import "./reset";

.App {
  padding: 0.1rem;
}

#header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 auto;
  color: var(--text);
  text-decoration: none;
}

.brand-header-logo {
  display: block;
  width: 100%;
  max-width: 320px;
  margin: auto;
}

.app-target-viewports {
  display: flex;
  flex-wrap: wrap;
  min-height: 80vh;
}

@media (max-width: 1005px) {
  .app-indexes {
    flex-direction: column-reverse;
  }
}

.page {
  width: 100%;
  min-height: 100vh;
}

.loginButton {
  background: var(--text);
  color: var(--background);
  padding: 0.66rem 1rem;
  border-radius: 4px;
  font-size: 1.1rem;
  margin: auto;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  width: fit-content;
  margin: 2rem auto;
  font-weight: bold;
  opacity: 0.88;
  transition: opacity ease 300ms;
  &:hover {
    opacity: 1;
  }
}

.loading-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid var(--accent2);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// .btn {
//   border: 1px solid #101010 !important;
//   border-radius: 4px !important;
//   background: none !important;
//   color: var(--text) !important;

//   &:hover {
//     text-decoration: underline;
//   }
// }
