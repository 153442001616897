.campaign-date {
  position: relative;
  width: 100%;
  margin-bottom: 0.33rem;
  padding: 0.33rem 0;
  display: flexs;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: all ease 300ms;

  &.open{
    height: 320px;
  }
}

.campaign-date-heading{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.campaign-date-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: .3rem;

  svg {
    width: 1rem;
  }
}


.campaign-date .rdtPicker {
  bottom: 100%;
}

