.campaignProductItem {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 5px;
}

.campaignProductItem-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}

.campaignProductItem-remove {
  position: relative;
  width: fit-content;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: none;
  opacity: 0.66;
  transition: all ease 300ms;
  cursor: pointer;

  svg {
    width: 12px;

    path {
      transition: fill ease 300ms;
    }
  }

  &:hover {
    opacity: 1;

    svg path {
      fill: crimson;
    }
  }
}
